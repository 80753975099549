.btn {
    font-family: 'Helvetica', 'Arial', sans-serif;
}

.card-deck .card {
    min-width: 220px;
}

.lead {
    font-size: 1.2em !important;
}

@import '~bootstrap/scss/bootstrap.scss';

$facebook-color: #3b5998;
$instagram-color: #405de6;
$youtube-color: #ff0000;
$soundcloud-color: #ff8800;
$spotify-color: #1db954;

html {
    min-height:100%;
    position: relative;
}

body {
    background: #111;
    color: white;
    font-family: 'Roboto', sans-serif;
    font-size: 0.9em;
    font-weight: 100;
    margin-bottom: 60px;
}

section {
    margin: 0 auto;
    h3 {
        font-family: 'Unica One', cursive;
        margin-bottom: .6em;
    }
    &.dark {
        background-color: #111;
        color: #eee;
    }
    &.light {
        background-color: #EEE;
        color: #111;
    }    
}

a, a:hover {
    color: #c82333;
}

/*
 * Navigation
 */

nav {
    background-color: rgba(17, 17, 17, 0.9);
    font-family: 'Unica One', cursive; 

    .navbar-brand {
        height: 70px;

        img {
            max-height: 60px;
        }
    }

    .navbar-nav {
        .nav-item {
            .nav-link {
                color: rgba(255, 255, 255, .5);
                background-color: transparent;
                border-bottom: .25rem solid transparent;

                &:hover {
                    border-bottom-color: rgba(255, 255, 255, .25);
                }
            }
        }
    }
}

/*
 * Social Media
 */

 .social-media-bar {
     background: white;

     .container {
         padding: 1em;
     }
 }

 .social-link {
    color: #333;
    padding: 0 0.7em;

    &.social-facebook {
       &:hover {
           color: $facebook-color;
       } 
    }
    &.social-instagram {
        &:hover {
            color: $instagram-color;
        } 
     }
     &.social-youtube {
        &:hover {
            color: $youtube-color;
        } 
     }
     &.social-soundcloud {
        &:hover {
            color: $soundcloud-color;
        } 
     }
     &.social-spotify {
        &:hover {
            color: $spotify-color;
        } 
     }                                   
 }

 .social-link, .social-link:hover {
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in; 
 }

main {
    position: relative;
    section {
        padding: 3em 0;
    }
}

#Hero {
    &.hero-scheme {
        background: #000;
    }
}

#Merchandise {
    .merch-container {
        .merch-item {
            background: #fff;
            border: 1px solid #dedede;
            margin: 0.5em;
            padding: 1em;
            .merch-image {
                padding: 2em 0.2em;
            }
            .merch-name {
                border-top: 1px solid #dedede;
                font-size: 0.8em;
                font-weight: 700;
                line-height: 2em;
                padding: 0.5em 0 0;
                text-align: center;
                text-transform: uppercase;
            }
            .merch-cost {
                font-size: 1.3em;
                font-weight: bold;
                line-height: 1em;
                text-align: center;
            }
        }
    }
    .merch-order {
        .merch-btn-col {
            & > a {
                float: left;
            }
            & > div {
                float: right;
            }
            .merch-btn {
                font-size: 13px;
            }            
        }
    }

    .merch-disclaimer {
        font-size: 0.8em;
    }
}

#Events {
    & > div {
        text-align: center;
    }
}

#Media {
    .instafeed-container {
        display: flex;
        flex-wrap: wrap;
        .instafeed-item {
            overflow: hidden;
            padding: 2px;
            .instafeed-image {
                overflow: hidden;
                img {
                    -webkit-transition: all 0.5s ease-in-out;
                    transition: all 0.5s ease-in-out;
                    -o-object-fit: cover;
                    object-fit: cover;
                    height: 287px;
                    width: 100%;
                    transition: transform .2s;
                    &:hover{
                        transform: scale(1.2);
                    }
                }
            }
        }
    }
    .embed-container {
        position: relative;
        padding-bottom: 56.25%;
        height: 0; 
        overflow: hidden; 
        max-width: 100%; 
    }
    .embed-container iframe, .embed-container object, .embed-container embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

#Patreon {
    .patreon-text {
        font-size: 1.1em !important;
        text-align: center;
    }
    .patreon-link {
        text-align: center;
    }
}

 /*
  * Footer
  */

footer {
    background: white;
    bottom: 0;
    color: black;
    font-family: 'Unica One', cursive;    
    font-size: 0.9em;
    height: 60px;
    line-height: 60px;
    position: absolute;
    width: 100%;
}

@media only screen and (max-width: 600px) {
    nav {
        background-color: rgba(17, 17, 17, 1);
    }
}

@media only screen and (max-width: 399px) {
    .instafeed-image {
        img {
            height: 175px !important;
        }
    }
}

@media only screen and (min-width: 400px) and (max-width: 499px) {
    .instafeed-image {
        img {
            height: 200px !important;
        }
    }
}

@media only screen and (min-width: 500px) and (max-width: 575px) {
    .instafeed-image {
        img {
            height: 255px !important;
        }
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .instafeed-image {
        img {
            height: 175px !important;
        }
    }
}

@media only screen and (min-width: 767px) and (max-width: 990px) {
    .instafeed-image {
        img {
            height: 175px !important;
        }
    }
}

@media only screen and (min-width: 990px) and (max-width: 1200px) {
    .instafeed-image {
        img {
            height: 225px !important;
        }
    }
}